<template>
  <CCard body-wrapper>
    <CRow
      align-vertical="center"
      class="mb-3"
    >
      <CCol col="auto">
        <h1>
          {{ this.pageTitle }}
        </h1>
      </CCol>
    </CRow>
    <template v-if="accountConfigured" class="col-6 pb-3">
      <CAlert class="alert alert-success text-dark pb-3">
        <h4 class="mt-2 text-dark">Настройка завершена!</h4>
        <hr>
          <CRow class="col-6">
            <strong>{{ settings[0].title }}</strong>
          </CRow>
          <CRow class="col-6">
            <CCardText>
              {{ editForm.data['OrganizationBin'] }}
            </CCardText>
          </CRow>
          <CRow class="col-6 pt-1">
            <strong>{{ settings[1].title }}</strong>
          </CRow>
          <CRow class="col-6">
            <CCardText>
              {{ editForm.data['TradePointName'] }}
            </CCardText>
          </CRow>
      </CAlert>
      <template v-if="$store.getters.isUserOwner">
        <CInputCheckbox
          class="form-check-inline font-weight-bold pt-1 pb-2"
          :checked.sync="checkboxChecked"
          @change.capture="onOrOffWhiteList()"
          label="Ограничить доступ к личному кабинету"
        />
        <template v-if="!!checkboxChecked">
          <div class="col-5">
            <div class="pt-2 pb-2">Пользователи с правом доступа:</div>
            <template v-for="item in whitelist">
  <!--            если овнер -->
              <CInput
                v-if="item.isOwner"
                class="ownerEmail"
                type="text"
                :disabled="item.isOwner"
                :value="item.email"
              />
              <template v-if="!item.isOwner">
                <CInput
                  class="existEmail"
                  style="display: flex; margin-right: -2.9%"
                  type="text"
                  :value.sync="item.email"
                  :isValid.sync="item.isValid"
                  @keyup.capture="validateEmail(item)"
                  @keydown="validateEmail(item)"
                  @keyup="validateEmail(item)"
                  @blur.capture="validateEmail(item)"
                  @focusout="preventDefaultValidationStatus(item)"
                >
                  <template v-slot:append>
                    <CButtonClose
                      class="pl-2"
                      style="outline: none;"
                      @click.prevent="removeFromWhitelist(item.email)"
                    />
                  </template>
                </CInput>
              </template>
  <!--            одно такое-->
            </template>
<!--            <template v-for="newEmail in newEmails">-->
<!--              <CInput-->
<!--                class="newEmail"-->
<!--                style="display: flex; margin-right: -2.9%"-->
<!--                type="text"-->
<!--                :value.sync="newEmail.email"-->
<!--                :isValid="newEmail.isValid"-->
<!--                @keyup.capture="validateEmail(newEmail)"-->
<!--                @blur.capture="validateEmail(newEmail)"-->
<!--                @focusout.capture="addToWhitelist(newEmail)"-->
<!--              >-->
<!--                <template v-slot:append>-->
<!--                  <CButtonClose-->
<!--                    class="pl-2"-->
<!--                    style="outline: none;"-->
<!--                    @click.prevent="removeFromNewWhitelist(newEmail.email)"-->
<!--                  />-->
<!--                </template>-->
<!--              </CInput>-->
<!--            </template>-->
            <div>
              <CCardLink
                aria-label="Добавить пользователя"
                class="addEmail"
                @click.prevent="pushNewInputInWhiteList">
                Добавить пользователя
              </CCardLink>
            </div>
            <div class="pt-3">
              <CButton
                color="primary"
                class="primaryButton"
                @click.prevent="saveWhitelist"
                :disabled="whitelist.length < 1 || formHaveErrors"
              >
                Сохранить
              </CButton>
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-else-if="newAccount === true">
      <template v-if="step === 0">
        <CRow align-vertical="center">
          <CCol col="auto">
            <CButton

              color="primary"
              type="submit"
              @click="step=1"
            >
              Начать настройку
            </CButton>

          </CCol>
        </CRow>
      </template>
      <template v-else-if="step > 0">
        <CForm
          class="mt-5 general-settings"
        >
<!--          здесь 4 поля: БИН, Серт, Приватный ключ, Пароль -->
          <CInput
            key="OrganizationBin"
            label="БИН организации"
            :value.sync="editForm.data.OrganizationBin"
            :isValid="isValidationBin"
            :lazy="false"
            @keyup="validateField('bin')"
            @paste="validateField('bin')"
            @blur="validateField('bin')"
          />
          <CTextarea
            label="Сертификат"
            :value.sync="editForm.data['Certificate']"
            :isValid="isValidationCert"
            @blur="validateField('cert')"
          />
          <CTextarea
            label="Приватный ключ"
            :value.sync="editForm.data['Privatekey']"
            :isValid="isValidationPrivate"
            @blur="validateField('privateKey')"
          />
          <CInput
            key="Password"
            label="Пароль"
            :value.sync="editForm.data['Password']"
            :isValid="isValidationPass"
            @blur="checkField('pass')"
          />

          <CRow align-vertical="center">
            <CCol col="auto">
              <CButton
                color="primary"
                :disabled="checkDisabledButton()"
                @click.prevent="edit()"
              >
                <template v-if="editForm.inProcessing">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                  <span class="sr-only">Подождите...</span>
                </template>
                <template v-else>
                  Дальше
                </template>
              </CButton>
            </CCol>
            <CCol col="auto">
              <CCardText v-if="!accountConfigured" class="text-black-50 ">Шаг {{ this.step }} из 2</CCardText>
            </CCol>
          </CRow>
        </CForm>
      </template>
    </template>
    <template v-else>
      <CForm
        class="mt-5"
        @submit.prevent="edit"
      >
        <CElementCover
          v-if="loadingSettings"
          :opacity="0.8"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
          />
        </CElementCover>
        <template v-else-if="tradePoints.length > 0">
          <CCardTitle class="text-black-50">
            {{ settings[0].title }}: {{ editForm.data['OrganizationBin'] }}
          </CCardTitle>
          <br>
          <CSelect
            label="Торговая точка"
            :options="tradePoints"
            :value.sync="editForm.tradePoint"
          />
          <CRow align-vertical="center">
            <CCol col="auto">
              <CButton
                :class="editForm.tradePoint > 0 ? 'btn-primary' : 'bg-gray-200'"
                type="submit"
                :disabled="editForm.inProcessing || !(editForm.tradePoint > 0)"
                @click.prevent="edit()"
              >
                <template v-if="editForm.inProcessing">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                  <span class="sr-only">Подождите...</span>
                </template>
                <template v-else>
                  Сохранить
                </template>
              </CButton>
            </CCol>
            <CCol col="auto">
              <CCardText v-if="!accountConfigured" class="text-black-50 ">Шаг {{ this.step }} из 2</CCardText>
            </CCol>
          </CRow>
        </template>
        <template v-else>
          Для продолжения настройки в мобильном приложении Kaspi Pay необходимо добавить хотя бы одну торговую точку.
        </template>
      </CForm>
    </template>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import urls from '../../../router/urls';
import {customAxios} from "@/plugins/axios";

export default {
  name: 'List',

  data() {
    return {
      editForm: {
        data: {
          OrganizationBin: ''
        },
        inProcessing: false,
        tradePoint: -1,
        disabledNextButton: true,
      },
      previousLength: 0,
      organizationBin: '',
      isValidationSetupValid: undefined,
      isValidationBin: undefined,
      isValidationCert: undefined,
      isValidationPrivate: undefined,
      isValidationPass: undefined,
      checkboxWhiteList: false,
      checkboxChecked: false,
      inputWhiteListIsVisible: false,
      isValidateNewEmail: undefined,
      whitelist: [],
      loadingSettings: false,
      accountConfigured: false,
      settings: [],
      tradePoints: [],
      pageTitle: 'Настройки',
      step: -1,
      newAccount: false,
      formHaveErrors: false,
      // newEmail: '',
      newEmails: [],
      isUserOwner: false,
    };
  },

  created() {
    this.getSettings();
  },

  watch: {
    organizationBin() {
      this.previousLength = this.organizationBin.length
    }
  },
  methods: {
    async edit() {
      //fixed by Alexey M.
      if (this.step === 1) {
        if (this.editForm.data.OrganizationBin === '' || this.editForm.data.OrganizationBin === undefined) {
          this.isValidationBin = false;
          return false;
        }
        if (this.editForm.data.Certificate === '' || this.editForm.data.Certificate === undefined) {
          this.isValidationCert = false;
          return false;
        }
        if (this.editForm.data.Privatekey === '' || this.editForm.data.Privatekey === undefined) {
          this.isValidationPrivate = false;
          return false;
        }
        if (this.editForm.data.Password === '' || this.editForm.data.Password === undefined) {
          this.isValidationPass = false;
          return false;
        }
      }

        if (!this.accountConfigured) {
          // await customAxios.post(urls.urlRegisterKaspiAccount, this.editForm.data);
          localStorage.setItem('isConfigured', 'false');
          if (this.step === 1) {
            try {
              window.btoa(this.editForm.data.Certificate);
            } catch (e) {
              this.isValidationCert = false;
              this.editForm.inProcessing = false;
              return false;
            }

            try {
              window.btoa(this.editForm.data.Privatekey);
            } catch (e) {
              this.isValidationPrivate = false;
              this.editForm.inProcessing = false;
              return false;
            }

            try {
              this.editForm.inProcessing = true;
              const btoaCert = window.btoa(this.editForm.data.Certificate);
              const btoaPriv = window.btoa(this.editForm.data.Privatekey);
              await customAxios.post(
                urls.urlRegisterKaspiAccount,
                {
                  'TargetSystem': 'insales',
                  'OrganizationBin': this.editForm.data.OrganizationBin.trim(),
                  'Certificate': btoaCert.trim(),
                  'Privatekey': btoaPriv.trim(),
                  'Password': this.editForm.data.Password.trim(),
                }
              );
              await Swal.fire({
                icon: 'success',
                text: 'Регистрационные данные верные. Переходим на шаг 2',
                title: 'Успешно',
              });
              // this.step = 2;
              // await this.$router.push({name: 'admin.settings'});
              location.reload()
            } catch (e) {
              if (e.response) {
                let message = 'При загрузке настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';
                if (e.response.data.Message.toString() !== '') {
                  message = e.response.data.Message.toString();
                }
                // if (e.response.status === 400) {
                //   message = 'Данный БИН уже зарегистрирован в системе.';
                // }
                await Swal.fire({
                  icon: 'error',
                  text: message,
                  title: 'Ошибка!',
                });
              } else {
                console.log(e)
              }
            }
          } else if (this.step === 2) {
            try {
              this.editForm.inProcessing = true;
              await customAxios.put(
                urls.urlUpdateTradePoint + this.editForm.tradePoint,
                {}
              );
              await Swal.fire({
                icon: 'success',
                text: 'Торговая точка сохранена',
                title: 'Успешно',
              });
              location.reload();

            } catch (e) {
              if (e.response) {
                let message = 'При загрузке настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';
                if (e.response.data.Message.toString() !== '') {
                  message = e.response.data.Message.toString();
                }
                // if (e.response.status === 400) {
                //   message = 'Данный БИН уже зарегистрирован в системе.';
                // }
                await Swal.fire({
                  icon: 'error',
                  text: message,
                  title: 'Ошибка!',
                });
              } else {
                console.log(e)
              }
            }
          }

        } else {
          this.$store.commit('set', ['isConfigured', true]);
          localStorage.setItem('isConfigured', 'true');
        }

        this.editForm.inProcessing = false;
    },
    // async addToWhitelist(email) {
    //   if (email.email.length > 0 && email.isValid) {
    //     try {
    //       this.whitelist.push({email: email.email});
    //
    //       // email.email = '';
    //       // email.isValid = undefined;
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   } else {
    //     console.log('Впишите емейл');
    //   }
    // },
    async pushNewInputInWhiteList() {
      this.whitelist.push({ email: '', isValid: false });
      this.formHaveErrors = true;
    },
    async getFindedItemIndex(email) {
      let findedItemIndex = -1;
      this.whitelist.forEach(item => {
        if (item.email === email) {
          findedItemIndex = this.whitelist.indexOf(item)
        }
      })
      return findedItemIndex;
    },
    // async getFindedItemIndexInNewEmails(email) {
    //   let findedItemIndex = -1;
    //   this.newEmails.forEach(item => {
    //     if (item.email === email) {
    //       findedItemIndex = this.newEmails.indexOf(item)
    //     }
    //   })
    //   return findedItemIndex;
    // },
    async removeFromWhitelist(email) {
      let findedItemIndex = await this.getFindedItemIndex(email)
      if (findedItemIndex > -1) {
        this.whitelist.splice(findedItemIndex, 1);
        this.formHaveErrors = this.checkErrorInWhiteList(this.whitelist);
      }
    },
    // async removeFromNewWhitelist(email) {
    //   let findedItemIndex = await this.getFindedItemIndexInNewEmails(email)
    //   if (findedItemIndex > -1) {
    //     this.newEmails.splice(findedItemIndex, 1);
    //   }
    // },
    checkEmptyEmails(arr) {
      let emptyFound = false
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].email.length <= 0) {
          arr[i].isValid = false;
          emptyFound = true;
        }
      }
      return emptyFound;
    },
    checkErrorInWhiteList(arr) {
      let errorFound = false
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isValid === false) {
          errorFound = true;
        }
      }
      return errorFound;
    },
    removeDuplicates(arr) {
      const uniqueArr = [];

      arr.forEach(obj => {
        const isDuplicate = uniqueArr.some(uniqueObj => {
          return JSON.stringify(obj) === JSON.stringify(uniqueObj);
        });

        if (!isDuplicate) {
          uniqueArr.push(obj);
        }
      });

      return uniqueArr;
    },
    async saveWhitelist() {
      try {
        const emptyFound = this.checkEmptyEmails(this.whitelist);
        if (emptyFound === true) {
          return;
        }
        this.whitelist = this.removeDuplicates(this.whitelist);
        const resp = await customAxios.post(
          urls.urlGetWhitelist,
          {
              'whitelist': this.whitelist,
              'isWhitelistEnabled': this.checkboxChecked,
          });
        // console.log(resp);

        await Swal.fire({
          icon: 'success',
          text: resp.data.Data,
          title: 'Успех!',
        });

      } catch (e) {
        if (e.response) {
          let message = 'При сохранении настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';
          if (!e.response.data.Message.isEmpty()) {
            message = e.response.data.Message;
          }
          await Swal.fire({
            icon: 'error',
            text: message,
            title: 'Ошибка!',
          });
        } else {
          console.log(e);
        }
      } finally {
        this.loadingSettings = false;
      }
    },

    async getSettings() {
      this.loadingSettings = true;
      localStorage.setItem('isConfigured', 'false');

      try {
        const { data } = await customAxios.get(urls.urlAccountStatus);
        // console.log(data.Data);

        this.editForm.data['OrganizationBin'] = data.Data.OrganizationBin;
        this.settings.push(
          {
            'key': 'OrganizationBin',
            'title': 'БИН Организации',
            'value': data.Data.OrganizationBin,
          },
        );

        if (data.Data.AccountConfigured) {
          this.accountConfigured = true;
          this.$store.commit('set', ['isConfigured', true]);
          localStorage.setItem('isConfigured', 'true');

          this.editForm.data['TradePointName'] = data.Data.TradePointName;
          this.settings.push(
            {
              'key': 'TradePointName',
              'title': 'Торговая точка',
              'value': data.Data.TradePointName,
            },
          );
        } else {
          this.step = 2;
          this.pageTitle = 'Настройка: шаг ' + this.step;
          const listTradePointsData = await customAxios.get(urls.urlTradePoints);

          this.tradePoints.push(
            {
              'value': null,
              'label': '',
            }
          );
          listTradePointsData.data.Data.forEach(
            ({
               TradePointId,
               TradePointName
            }) => {
            if (TradePointName === data.Data.TradePointName) {
              this.editForm.tradePoint = TradePointId;
            }

            this.tradePoints.push(
              {
                'value': TradePointId,
                'label': TradePointName,
              }
            );
          })
        }

      } catch (e) {
        if (e.response) {
          if (e.response.status === 401) {
            this.step = 0;
            this.newAccount = true;
          } else {
            let message = 'При загрузке настроек возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';
            if (!e.response.data.Message.isEmpty()) {
              message = e.response.data.Message;
            }
            await Swal.fire({
              icon: 'error',
              text: message,
              title: 'Ошибка!',
            });

//            await this.$router.push({name: 'admin.settings'});
          }
        }
      } finally {
        this.loadingSettings = false;
      }
      this.getWhiteList();
    },
    async getWhiteList() {
      this.loadingSettings = true;
      try {
        const whitelistResponse = await customAxios.get(urls.urlGetWhitelist);

        this.checkboxChecked = whitelistResponse.data.Data.isWhitelistEnabled;

        whitelistResponse.data.Data.whitelist.forEach(
          ({
             id,
             email,
             is_owner,
           }) => {
            this.whitelist.push(
              {
                'id': id,
                'email': email,
                'isOwner': is_owner,
              }
            );
          })
      } catch (e) {
        console.log(e.response);
        if (e.response) {
          if (e.response.status >= 400) {
            let message = 'При загрузке настроек возникла ошибка.';
            if (!e.response.data.Message.isEmpty()) {
              message = e.response.data.Message;
            }
            await Swal.fire({
              icon: 'error',
              text: message,
              title: 'Ошибка!',
            });
          }
        }
        console.error('error', e)
      } finally {
        this.loadingSettings = false;
      }
    },
    checkField(field) {
      switch (field) {
        case 'bin':
          try {
            this.isValidationBin = event.target.value.length > 0;
            return this.isValidationBin;
          } catch (e) {
            console.log(e);
          }
          return false;
        case 'cert':
          try {
            this.isValidationCert = event.target.value.length > 0;
            return this.isValidationCert;
          } catch (e) {
            console.log(e);
          }
          return false;
        case 'private':
          try {
            this.isValidationPrivate = event.target.value.length > 0;
            return this.isValidationPrivate;
          } catch (e) {
            console.log(e);
          }
          return false;
        case 'pass':
          try {
            this.isValidationPass = event.target.value.length > 0;
            return this.isValidationPass;
          } catch (e) {
            console.log(e);
          }
          return false;
      }
    },
    async onOrOffWhiteList() {
      try {
        const resp = await customAxios.post(
          urls.urlEnableWhitelist,
          {
            enable: !this.checkboxChecked
          });

        let message = '';
        if (!this.checkboxChecked === true) {
          message = 'Режим ограниченного доступа к Личному кабинету модуля выключен';
          this.whitelist = [];
          this.getWhiteList();
        } else {
          message = 'Режим ограниченного доступа к Личному кабинету модуля включен';
        }
        await Swal.fire({
          icon: 'success',
          text: message,
          title: 'Успех!',
        });
      } catch (e) {
        if (e.response) {
          let type = 'отключении'
          if (!this.checkboxChecked === true) {
            type = 'включении'
          }
          let message = 'При ' + type + ' функции ограничения доступа к личному кабинету возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику.';

          await Swal.fire({
            icon: 'error',
            text: message,
            title: 'Ошибка!',
          });
        } else {
          console.log(e);
        }
      }
    },
    validateEmail(email) {
      this.formHaveErrors = this.checkEmptyEmails(this.whitelist);
      if (event.target.value <= 0) {
        email.isValid = undefined;
        this.formHaveErrors = true;
        return false;
      }

      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      email.isValid = re.test(String(event.target.value).toLowerCase());

      this.formHaveErrors = this.checkErrorInWhiteList(this.whitelist);
    },
    preventDefaultValidationStatus(element) {
      if (element.isValid === true) {
        element.isValid = undefined;
      }
    },
    validateField(field) {
      switch (field) {
        case 'bin':
          try {
            if (event.target.value.length === 0) {
              this.isValidationBin = false;
              return false;
            }
            if (this.editForm.data.OrganizationBin.length > 12) {
              this.editForm.data.OrganizationBin = this.editForm.data.OrganizationBin.slice(0, 12);
              // this.isValidationBin = true;
              // return true;
            }
            if (!/^\d*[0-9]\d*$/.test(this.editForm.data.OrganizationBin)) {
              this.isValidationBin = false;
              this.checkDisabledButton()
              return false;
            }
            if (this.editForm.data.OrganizationBin.parseInt < 0) {
              this.isValidationBin = false;
              this.checkDisabledButton()
              return false;
            }
          } catch (e) {
            console.log(e);
            this.isValidationBin = false;
            this.checkDisabledButton()
            return false;
          }
          this.isValidationBin = true;
          this.checkDisabledButton()
          break;
        case 'cert':
          try {
            window.btoa(this.editForm.data.Certificate);

            let cert = event.target.value;
            if (cert !== undefined) {
              if (cert.length === 0) {
                this.isValidationCert = false;
                this.checkDisabledButton()
                return false;
              }
            } else {
              this.isValidationCert = false;
              this.checkDisabledButton()
              return false;
            }
          } catch (e) {
            this.isValidationCert = false;
            this.checkDisabledButton()
            return false;
          }
          this.isValidationCert = true;
          this.checkDisabledButton()
          break;
        case 'privateKey':
          try {
            window.btoa(this.editForm.data['Privatekey']);

            let privateKey = event.target.value;
            if (privateKey !== undefined) {
              if (privateKey.length === 0) {
                this.isValidationPrivate = false;
                this.checkDisabledButton()
                return false;
              }
            } else {
              this.isValidationPrivate = false;
              this.checkDisabledButton()
              return false;
            }
          } catch (e) {
            this.isValidationPrivate = false;
            this.checkDisabledButton()
            return false;
          }
          this.isValidationPrivate = true;
          this.checkDisabledButton()
          break;
        case 'pass':
          try {
            let pass = event.target.value;
            if (pass !== undefined) {
              if (pass.length === 0) {
                this.isValidationPass = undefined;
                this.checkDisabledButton()
                return false;
              }
            } else {
              this.isValidationPass = undefined;
              this.checkDisabledButton()
              return false;
            }
          } catch (e) {
            this.isValidationPass = false;
            this.checkDisabledButton()
            return false;
          }
          this.isValidationPass = true;
          this.checkDisabledButton()
          break;
        default:
          break;
      }
      this.isValidationSetupValid = true;
      this.checkDisabledButton()
      return true;
    },
    checkDisabledButton() {
      return !this.isValidationBin || !this.isValidationCert || !this.isValidationPrivate || !this.isValidationPass;
    },
  },
};
</script>

<style scoped>

</style>
